import { createMutation } from 'react-query-kit';
import { ApiRoute } from '@zep/api/apiRoute.const.ts';
import { apiClient } from '@zep/utils/api.ts';

import { PlanType, UserType } from '../types';

/////////////////////////////////////////////////////

type LoginVariables = {
  accessToken: string;
  authType: string;
  localeCode: string;
};

type LoginResponse = {
  data: {
    token: string;
    isAdmin: boolean;
    isNewUser: boolean;
    locale: string;
    userName: string;
    memberType?: UserType;
    // 아직 부분적으로 사용중
    userType: UserType;
    expiredAt: string;
    grade: string;
    registrationStatus?: boolean;
    planType: PlanType;
  };
};

export const useLogin = createMutation<LoginResponse, LoginVariables>({
  mutationFn: (variables: LoginVariables) =>
    apiClient
      .post<LoginResponse>(ApiRoute.API_LOGIN, {
        accessToken: variables.accessToken,
        authType: variables.authType,
        localeCode: variables.localeCode,
      })
      .then(res => ({
        ...res,
        data: {
          ...res.data?.data,
          userType: res.data.data?.memberType || res.data.data.userType,
        },
      })),
});
