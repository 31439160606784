import { getEmptyLayout } from '../layout';
import { getDefaultStaticProps, isInAppBrowser } from '../utils';
import InAppLoginPageView from '../views/InAppLoginPageView';
import LoginPageView from '../views/LoginPageView';

export default function LoginPage() {
  return isInAppBrowser() ? <InAppLoginPageView /> : <LoginPageView />;
}

export const getStaticProps = getDefaultStaticProps;

LoginPage.getLayout = getEmptyLayout;
