import { useMemo } from 'react';
import { isInAppBrowser } from '@zep/utils';
import { useTranslation } from 'next-i18next';

import styles from './InAppLoginPage.module.scss';

export default function InAppLoginPageView() {
  const { t } = useTranslation();
  const openBrowser = () => {
    const externalBrowserLink = getInAppExternalLink(window.location.origin);

    window.open(externalBrowserLink, '_blank');
  };

  const isShownExternalLinkButton = useMemo(() => {
    if (window.navigator.userAgent.match(/kakaotalk|line/i)) {
      return true;
    }

    return !window.navigator.userAgent.match(/iphone|ipad|ipod/i);
  }, []);

  return (
    <div className={styles.container}>
      <img src="/assets/ZEPQuiz-logo.svg" className={styles.logo} alt="logo" />
      <p className={styles.inapp_browser_description}>
        {t('inAppLogin.description.first')}
      </p>
      <img
        src="/assets/browsers.png"
        className={styles.browsers}
        alt="browsers"
      />
      <p className={styles.inapp_browser_description}>
        {t('inAppLogin.description.second')}
      </p>
      {isShownExternalLinkButton && (
        <button className={styles.open_button} onClick={openBrowser}>
          {t('inAppLogin.open.button')}
        </button>
      )}
    </div>
  );
}

/**
 * 카카오톡이나 라인 등의 인앱에서 외부 링크를 열 때 사용하는 함수
 * 해당되지 않는 경우에는 일반 링크를 리턴한다
 * @param link 넣고자 하는 링크
 */
const getInAppExternalLink = (link: string) => {
  if (!isInAppBrowser()) {
    return link;
  }

  // 아는게 생길때마다 하나씩 추가 바람
  const kakaotalk = /kakaotalk/i;
  const line = /line/i;

  const iOS = /iphone|ipad|ipod/i;

  if (navigator.userAgent.match(kakaotalk)) {
    return `kakaotalk://web/openExternal?url=${encodeURIComponent(link)}`;
  } else if (navigator.userAgent.match(line)) {
    return `${link}${
      link.indexOf('?') !== -1 ? '&' : '?'
    }openExternalBrowser=1`;
  }

  if (navigator.userAgent.match(iOS)) {
    return link;
  } else {
    // 안드로이드는 intent 스키마 전달
    return `intent://${link.replace(
      /https?:\/\//i,
      '',
    )}#Intent;scheme=http;package=com.android.chrome;end`;
  }
};
