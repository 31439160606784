import { useGoogleLogin } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useLogin } from '../api/auth';
import { useUser } from '../lib/auth';
import { getBrowserLanguage } from '../utils';
import { zepAnalytics } from '../utils/analytics';

import styles from './LoginPage.module.scss';

export default function LoginPageView() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setUser } = useUser();
  const { mutate } = useLogin({
    onSuccess: async data => {
      const callbackUrl = router.query.callbackUrl?.toString() || '/public';

      setUser({
        token: data.data.token,
        type: data.data.memberType,
        localeCode: data.data.locale,
        admin: data.data.isAdmin,
        username: data.data.userName,
        expiredAt: data.data.expiredAt,
        registrationStatus: data.data.registrationStatus,
        planType: data.data.planType,
      });

      if (
        !data.data.registrationStatus ||
        data.data.userType?.toLowerCase() === 'unknown'
      ) {
        router.replace('/sign-up/type');
        return;
      }

      await router.push(callbackUrl);
    },
  });

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      mutate({
        accessToken: tokenResponse.access_token,
        authType: 'google',
        localeCode: getBrowserLanguage(),
      });
      zepAnalytics.track('login_complete', {
        location: 'header',
      });
    },
    onError: error => {
      alert(error);
    },
  });

  return (
    <>
      <div className={styles.login_container}>
        <div className={styles.login_wrapper}>
          <div className={styles.login_header}>
            <img
              className={styles.img_logo500}
              src="/assets/ZEPQuiz-logo.svg"
              alt="logo"></img>
          </div>

          <div onClick={() => login()} className={styles.google_login_button}>
            <img
              className={styles.icon_google}
              src="/assets/Icon_google.png"
              alt="google"
            />
            <div>{t('login.google.button')}</div>
          </div>
        </div>
      </div>
    </>
  );
}
